<template>
    <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
        <div class="col-span-2 md:col-span-3 xl:col-span-4">
            <h3 class="mb-4">Sélectionner l’article que vous souhaitez personnaliser</h3>
        </div>

        <div class="col-span-1 hidden flex-col pr-8 md:flex">
            <div class="flex h-12 w-full items-center justify-between bg-theme-yellow px-8 font-bold">
                Catégories
                <span
                    v-if="selectedCategory"
                    class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-sm text-black hover:bg-black hover:text-white"
                    @click="selectedCategory = null"
                    >x</span
                >
            </div>
            <div class="flex flex-grow flex-col bg-gray-100 p-4">
                <div
                    v-for="category in categoriesCatalog"
                    :key="category.code"
                    :class="[isCategorySelected(category.code) ? 'active' : '', 'category']"
                    @click="selectCategory(category.code)"
                >
                    <div class="flex w-20 items-center justify-center bg-white">
                        <img class="h-20 bg-white" :src="category.image" :alt="category.imageAlt" />
                    </div>
                    <span class="ml-2 font-bold text-gray-700">{{ category.name }}</span>
                </div>
            </div>
        </div>
        <div class="col-span-2 grid auto-rows-min grid-cols-4 gap-4 xl:col-span-3">
            <div
                v-for="product in products"
                :key="product.reference"
                class="col-span-2 flex flex-col border-2 border-gray-300 p-4 text-gray-700 xl:col-span-1"
            >
                <span class="mb-4 flex h-64 items-center">
                    <img class="m-auto max-h-full" :src="product.productImage" :alt="product.designation" />
                </span>
                <span class="mb-2 h-12 font-bold">{{ product.designation }}</span>
                <span class="text-xs">à partir de</span>
                <div class="mb-2 font-bold">
                    <span class="text-xl">{{ getDisplayedPrice(product) }}</span>
                    <span class="text-sm">&nbsp;net ht</span>
                </div>
                <button class="btn-action btn-blue h-11 w-full" @click.prevent="selectProduct(product.reference)">
                    Sélectionner
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import categoriesCatalog from "assets/data/categories.json";
import productsCatalog from "assets/data/products";

import { applyMarginRise2025, formatPrice } from "../helpers/priceHelper";
import { createNewArticle, getLowestPriceFromProduct } from "../helpers/projectHelper";
import { toRaw } from "vue";

export default {
    name: "catalog",
    data() {
        return {
            productsCatalog,
            categoriesCatalog,
            selectedCategory: null,
        };
    },
    computed: {
        products() {
            const products = this.productsCatalog.filter((product) => product.display !== false);
            if (this.selectedCategory) {
                return products.filter((product) => product.categoryCode === this.selectedCategory.code);
            }
            return products;
        },
        project() {
            return this.$store.getters["project/project"];
        },
    },
    methods: {
        selectProduct(productReference) {
            const newArticle = createNewArticle(
                this.products.find((product) => product.reference === productReference),
            );

            this.$store.dispatch("project/addArticle", newArticle);
        },
        selectCategory(categoryCode) {
            this.selectedCategory = this.categoriesCatalog.find((category) => category.code === categoryCode);
        },
        isCategorySelected(categoryCode) {
            return this.selectedCategory && this.selectedCategory.code === categoryCode;
        },
        getDisplayedPrice(product) {
            const minPrice = getLowestPriceFromProduct(toRaw(product));
            const price2025 = applyMarginRise2025(minPrice);
            return formatPrice(price2025);
        },
    },
};
</script>

<style lang="scss">
.category {
    @apply mb-2 flex cursor-pointer items-center bg-gradient-to-r transition duration-300 hover:from-white;
    &.active {
        @apply from-white to-theme-yellow;
    }
}
</style>
