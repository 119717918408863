import { v4 as uuidv4 } from "uuid";

import productsCatalog from "../../../../assets/data/products";
import {
    ARTICLE_STATUS_FINISH,
    ARTICLE_STATUS_NOT_FINISH,
    PRODUCT_FINISHING,
    PRODUCT_FINISHING_PRINTING,
} from "../services/constants";
import { marginCalculation } from "./priceHelper";

function getNewArticleId() {
    return uuidv4();
}

function createNewArticle(product) {
    const article = {
        id: getNewArticleId(),
        finishing: null,
        status: "EDITING",
        customization: [],
    };

    if (Object.keys(product.minQuantity).length < 2) {
        article.finishing = Object.keys(product.minQuantity)[0].toUpperCase();
    }

    article.quantity = Object.keys(product.buyPrices).reduce((acc, size) => {
        acc[size] = 0;
        return acc;
    }, {});

    article.reference = product.reference;
    article.designation = product.designation;
    article.image = product.productImage;
    article.imageAlt = product.designation;
    article.categoryCode = product.categoryCode;
    article.color = product.default.color;
    article.bat_imageLocation = product.bat_imageLocation;
    if (product.grammage) {
        article.grammage = product.grammage;
    }

    return article;
}

function switchProductGrammage(article, grammageValue) {
    if (article.grammage === grammageValue) {
        return article;
    }

    const currentArticleProduct = productsCatalog.find((product) => product.reference === article.reference);

    const newArticle = currentArticleProduct.grammageOptions.find((grammage) => {
        return grammage.value === grammageValue;
    });

    if (!newArticle) {
        return article;
    }
    const newArticleRef = newArticle.reference;

    if (newArticleRef === article.reference) {
        return article;
    }

    const newProduct = productsCatalog.find((product) => product.reference === newArticleRef);

    return {
        ...article,
        reference: newProduct.reference,
        designation: newProduct.designation,
        description: newProduct.description,
        characteristics: newProduct.characteristics,

        grammage: newProduct.grammage,
    };
}

function isArticleQuantityValid(article) {
    return Object.values(article.quantity).reduce((acc, qty) => {
        if (acc) {
            return acc;
        }
        return qty > 0;
    }, false);
}

function isArticleProductQuantityValid(article) {
    const totalQuantity = Object.values(article.quantity).reduce((acc, qty) => acc + qty, 0);
    const product = productsCatalog.find((prod) => prod.reference === article.reference);
    let valid = false;

    if (article.finishing === PRODUCT_FINISHING_PRINTING) {
        valid = totalQuantity >= product.minQuantity.printing;
    } else {
        valid = totalQuantity >= product.minQuantity.embroidery;
    }

    return valid;
}

function isArticleFinishingValid(article) {
    return article.finishing !== null;
}

function isArticleCustomizationValid(article) {
    if (article.customization.length < 1) return false;

    return article.customization.reduce((acc, cstm) => {
        if (acc) return acc;

        if (cstm.type === "image") return cstm.source !== null;

        if (cstm.type === "text") return cstm.content.value !== null;

        return false;
    }, false);
}

function isArticleGrammageValid(article) {
    if (article.grammage === undefined) return true;

    if (article.grammage === 0) return false;

    return true;
}

function isArticleValid(article) {
    const isValidQuantity = isArticleQuantityValid(article);
    if (!isValidQuantity) return false;

    const isValidMinQuantity = isArticleProductQuantityValid(article);
    if (!isValidMinQuantity) return false;

    const isValidFinishing = isArticleFinishingValid(article);
    if (!isValidFinishing) return false;

    const validCustomization = isArticleCustomizationValid(article);
    if (!validCustomization) return false;

    const validGrammage = isArticleGrammageValid(article);
    if (!validGrammage) return false;

    return true;
}

function getArticleStatusNoEdit(article) {
    const statusValid = isArticleValid(article);

    return statusValid ? ARTICLE_STATUS_FINISH : ARTICLE_STATUS_NOT_FINISH;
}

function getFinishingLabel(finishing) {
    return PRODUCT_FINISHING[finishing] !== undefined ? PRODUCT_FINISHING[finishing] : "Non défini";
}

function getFinishedArticles(articles) {
    return articles.filter((article) => getArticleStatusNoEdit(article) === ARTICLE_STATUS_FINISH);
}

function isProjectValid(project) {
    return project.articles && project.articles.length > 0 && getFinishedArticles(project.articles).length > 0;
}

function isLocationProductAvailableForFinishing(productRef, location, finishing) {
    const pricesKeyText =
        finishing === PRODUCT_FINISHING_PRINTING ? "textPrintingQuantities" : "textEmbroideryQuantities";
    const pricesKeyLogo =
        finishing === PRODUCT_FINISHING_PRINTING ? "logoPrintingQuantities" : "logoEmbroideryQuantities";

    const product = productsCatalog.find((prod) => prod.reference === productRef);
    const availablePricesForLocation = Object.keys(product.locations[location].prices);

    const isLocationAvailable =
        availablePricesForLocation.includes(pricesKeyText) && availablePricesForLocation.includes(pricesKeyLogo);

    return isLocationAvailable;
}

function getLowestPriceFromProduct(product) {
    const colorPrices = Object.values(product.buyPrices);
    const firstColorPrices = Object.values(colorPrices[0]);

    const lowestPrice = firstColorPrices.reduce((acc, price) => {
        return price < acc ? price : acc;
    }, firstColorPrices[0]);

    return marginCalculation(lowestPrice, product.marge);
}

export {
    getNewArticleId,
    createNewArticle,
    switchProductGrammage,
    isArticleValid,
    isArticleQuantityValid,
    isArticleProductQuantityValid,
    isArticleFinishingValid,
    isArticleCustomizationValid,
    getArticleStatusNoEdit,
    getFinishingLabel,
    getFinishedArticles,
    isProjectValid,
    isLocationProductAvailableForFinishing,
    getLowestPriceFromProduct,
};
