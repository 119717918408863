import { CURRENCY_NAME, VAT_RATE } from "../services/constants";

function formatPrice(price) {
    return price.toLocaleString("fr-FR", {
        style: "currency",
        currency: CURRENCY_NAME,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

function round(price, decimals = 2) {
    const factorOfTen = 10 ** decimals;
    return Math.round(price * factorOfTen) / factorOfTen;
}

function getVATAmount(price) {
    return (price * VAT_RATE) / 100;
}

function marginCalculation(costPrice, marge) {
    return (costPrice * marge) / 100 + costPrice;
}

function applyMarginRise2025(costPrice) {
    const marginRisePercent2025 = 2.5;
    return marginCalculation(costPrice, marginRisePercent2025);
}

function buyPriceOperation(totalPriceArticles, totalPriceCustomizations) {
    return applyMarginRise2025(totalPriceArticles + totalPriceCustomizations);
}

function costPriceOperation(buyPrice, multiplicator) {
    return buyPrice * multiplicator;
}

function removeMarkUpFromPrice(costPrice, marge) {
    return ((100 - marge) / 100) * costPrice;
}

export {
    formatPrice,
    getVATAmount,
    round,
    buyPriceOperation,
    removeMarkUpFromPrice,
    costPriceOperation,
    marginCalculation,
    applyMarginRise2025,
};
