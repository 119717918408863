import catalog from "assets/data/products";

import self from "../../index";
import { getColorLabel } from "../helpers/colorHelper";
import { getLocationName } from "../helpers/locationHelper";
import { buyPriceOperation, removeMarkUpFromPrice, round, marginCalculation } from "../helpers/priceHelper";
import { getFinishedArticles } from "../helpers/projectHelper";
import store from "../store";
import generateBats from "./batGenerator";
import {
    CUSTOMIZATION_TYPE_IMAGE,
    CUSTOMIZATION_TYPE_TEXT,
    PRODUCT_FINISHING,
    PRODUCT_FINISHING_EMBROIDERY,
    PRODUCT_FINISHING_PRINTING,
} from "./constants";

const {
    modules: { iframeApi },
} = self.app;

function getLocationPrices(prices, finishing, type) {
    let locationPrice = {};

    if (type === CUSTOMIZATION_TYPE_TEXT) {
        if (finishing === PRODUCT_FINISHING_EMBROIDERY) {
            locationPrice = prices.textEmbroideryQuantities;
        } else if (finishing === PRODUCT_FINISHING_PRINTING) {
            locationPrice = prices.textPrintingQuantities;
        }
    } else if (type === CUSTOMIZATION_TYPE_IMAGE) {
        if (finishing === PRODUCT_FINISHING_EMBROIDERY) {
            locationPrice = prices.textEmbroideryQuantities;
        } else if (finishing === PRODUCT_FINISHING_PRINTING) {
            locationPrice = prices.textPrintingQuantities;
        }
    }

    return locationPrice;
}

function getQuantityDecreasingPart(prices, customAmount) {
    // If amount is inferior to quantity part or if it's the last loop (max quantity part value)
    return Object.keys(prices).find((qty, index) => customAmount <= qty || Object.keys(prices).length - 1 === index);
}

function getTotalBuyPriceArticles(article, product) {
    return Object.keys(article.quantity).reduce((acc, size) => {
        const qty = article.quantity[size];
        const productPrice = product.buyPrices[size][article.color];
        const price = round(marginCalculation(productPrice, product.marge), 2);
        return acc + price * qty;
    }, 0);
}

function getTotalBuyPriceByCustomizations(article, product, articleQuantity) {
    return article.customization.reduce((acc, custom) => {
        const { prices } = product.locations[custom.location];
        const customizationPrices = getLocationPrices(prices, article.finishing, custom.type);

        const quantityPart = getQuantityDecreasingPart(customizationPrices, articleQuantity);
        const customPrice = customizationPrices[quantityPart];
        const price = round(marginCalculation(customPrice, product.marge), 2);

        return acc + price * articleQuantity;
    }, 0);
}

function getCodeProduct(finishing, codes) {
    return finishing === PRODUCT_FINISHING_PRINTING ? codes.printing : codes.embroidery;
}

function getArticlesTotalPrice(articles) {
    return articles.reduce((acc, art) => acc + art.PV, 0);
}

function pricesCalculation(articles) {
    return articles.map((article) => {
        const quoteArt = { ...article };
        const product = catalog.find((prod) => prod.reference === quoteArt.reference);

        const totalPriceArticles = getTotalBuyPriceArticles(quoteArt, product);

        const totalArticleQuantity = Object.values(quoteArt.quantity).reduce((acc, qty) => acc + qty, 0);

        const totalPriceCustomizations = getTotalBuyPriceByCustomizations(quoteArt, product, totalArticleQuantity);

        quoteArt.PV = buyPriceOperation(totalPriceArticles, totalPriceCustomizations);
        if (!quoteArt.PV || quoteArt.PV <= 0) {
            throw new Error("Price is undefined");
        }
        quoteArt.PR = removeMarkUpFromPrice(quoteArt.PV, product.marge);
        quoteArt.PA = quoteArt.PR;

        quoteArt.code = getCodeProduct(quoteArt.finishing, product.legallaisCode);

        return quoteArt;
    });
}

function cleanCustomization(articles) {
    return articles.map((article) => {
        article.customization = article.customization.map((cstm) => ({
            location: cstm.location,
            locationLabel: getLocationName(cstm.location),
            type: cstm.type,
            hideImageBackground: cstm.hideImageBackground,
        }));
        return article;
    });
}

function addLabels(articles) {
    return articles.map((article) => ({
        ...article,
        finishingLabel: PRODUCT_FINISHING[article.finishing],
        colorLabel: getColorLabel(article.color),
    }));
}

function roundArticlesPrices(articles) {
    return articles.map((article) => {
        const quoteArt = { ...article };

        quoteArt.PA = round(quoteArt.PA, 4);
        quoteArt.PR = round(quoteArt.PR, 4);
        quoteArt.PV = round(quoteArt.PV, 4);

        return quoteArt;
    });
}

function URIEncode(string) {
    return encodeURIComponent(string).replace(/\(/g, "%28").replace(/\)/g, "%29");
}

async function hashQuote(quoteComposition) {
    const message = URIEncode(JSON.stringify(quoteComposition));
    const msgUint8 = new TextEncoder().encode(`${message}${process.env.VUE_SECRET_HASH}`);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    quoteComposition.hash = hashHex;
    return quoteComposition;
}

export default async function generateQuote(project) {
    const quoteComposition = { ...project };
    let { articles } = quoteComposition;

    articles = getFinishedArticles(articles);
    articles = await generateBats(articles);
    articles = pricesCalculation(articles);
    articles = cleanCustomization(articles);
    articles = addLabels(articles);
    articles = roundArticlesPrices(articles);

    quoteComposition.articles = articles;

    quoteComposition.price = round(getArticlesTotalPrice(quoteComposition.articles), 4);

    try {
        const quote = await store.dispatch("project/saveQuote", quoteComposition);
        console.log("quote", quote);
        // await fetch quote, get quote with hash
        const quoteCompositionWithHash = await hashQuote(quote.composition);
        const uriEncodedComposition = URIEncode(JSON.stringify(quoteCompositionWithHash));
        iframeApi.sendEvent("quoteGenerated", uriEncodedComposition);
    } catch (error) {
        throw new Error(error);
    }
}
